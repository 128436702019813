/**
 * 数据分析-楼盘信息统计-详情列表
 * luxinwen
 * 2023-04-26
 */
 <style scoped lang="less">
  .card-wrapper {
    display: flex;
    margin: 20px 0 0 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    .card {
      margin-right: 20px;
      width: 15%;
      min-width: 200px;
      margin-bottom: 20px;
    }
    p {
      font-size: 22px;
      font-weight: bold;
      line-height: 50px;
      text-align: center;
    }
  }
  .card-container {
    flex: 0 0 600px;
  }
  .card-container + .card-container {
    margin: 0 0 0 10px;
  }
  .overflow-x {
    overflow-x: auto;
  }
  .chart_box {
    display: flex;
    .right {
      width: 180px;
    }
    .chart {
      flex: 1;
    }
  }
</style>
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_ANALYSIS_PROJECT_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Select class="width-m" v-model="formData.timeType">
              <Option v-for="(item, index) in typeList" :key="index" :value="item.type">{{ item.label }}</Option>
            </Select>
          </FormItem>
          <FormItem v-show="formData.timeType==5">
            <DatePicker class="width-m" v-model="formData.time" type="daterange" placeholder="请选择时间" />
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <!-- <div class="page-main-content" v-grant="'OSP_ANALYSIS_PROJECT_EXPORT'">
        <Button type="primary" @click="exportData">导出</Button>
        <Button @click="showRecord">查看导出记录</Button>
      </div> -->
      <div class="page-main-content">
        <div class="card-wrapper">
          <Card class="card">
            <template #title>会员总数</template>
            <p>{{info.memberNum||'0'}}</p>
          </Card>
          <Card class="card">
            <template #title>经纪人注册人数</template>
            <p>{{info.certifyNum||'0'}}</p>
          </Card>
          <Card class="card">
            <template #title>访问人数</template>
            <p>{{info.visitorNum||'0'}}</p>
          </Card>
          <Card class="card">
            <template #title>访问次数</template>
            <p>{{info.visitNum||'0'}}</p>
          </Card>
          <Card class="card">
            <template #title>新用户数量</template>
            <p>{{info.newMemberNum||'0'}}</p>
          </Card>
          <Card class="card">
            <template #title>新用户访问占比</template>
            <p>{{info.newVisitorProp||'0'}}%</p>
          </Card>
          <Card class="card">
            <template #title>今日活跃留存人数</template>
            <p>{{info.todayActiveNum||'0'}}</p>
          </Card>
        </div>
        <div class="card-wrapper">
          <Card style="width:100%">
            <template #title>
              <span class="card-title"></span>
            </template>
            <div class="chart_box">
              <sp-chart class="chart" v-model="option" type="line" :data="chartData" :loading="loading"></sp-chart>
              <div class="right">
                <RadioGroup v-model="button1" type="button" button-style="solid" @on-change="typeChange">
                  <Radio label="DAY">日增长量</Radio>
                  <Radio label="MONTH">月增长量</Radio>
                </RadioGroup>
              </div>
            </div>
          </Card>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        button1: 'DAY',
        typeList: [{ type: 1, label: '今天' }, { type: 2, label: '昨天' }, { type: 3, label: '过去7天' }, { type: 4, label: '过去30天' }, { type: 5, label: '自定义' }],
        formData: {
          time: [],
          timeType: 1
        },
        info: {},
        chartData: [],
        loading: false,
        option: {
          title: {
            text: '日增长量'
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [1, 2, 3]
          },
          yAxis: {
            type: 'value',
            minInterval: 1,
          },
          series: [
            {
              data: [],
              type: 'line',
              smooth: true,
              areaStyle: {
                shadowColor: 'rgba(148, 95, 185, 0.5)',
                shadowBlur: 20,  // 阴影
                color: '#2a82e4'
              },
            },
          ],
          // tooltip: {
          //   trigger: 'axis',
          //   formatter: function (params) {
          //     return params[0].axisValue;
          //   }
          // }

        },
        size: {
          height: '400px',
          width: '800px'
        }

      };
    },
    created() {
      this.getData();
      this.getChartData();
    },
    methods: {
      // 切换图标类型
      typeChange(e) {
        if (e === 'DAY') {
          this.$set(this.option.title, 'text', '日增长量');
        } else {
          this.$set(this.option.title, 'text', '月增长量');
        }
        this.getChartData();
      },
      getChartData() {
        this.$axios({
          url: this.$api.member.queryMemberIncreaseDetail,
          data: {
            queryType: this.button1
          },
          beforeSend: () => {
            this.loading = true;
          },
          complete: () => {
            this.loading = false;
          }
        }).then(data => {
          if (data && data.length) {
            this.chartData = data.map(item => item.memberNum);
            this.option.xAxis.data = data.map(item => item.dateTimeStr);
          }
        });
      },
      getTime(k) {
        let obj = {
          queryStartDate: '',
          queryEndDate: ''
        };
        let day_1 = 1 * 24 * 3600 * 1000;
        let day_7 = 7 * 24 * 3600 * 1000;
        let day_30 = 30 * 24 * 3600 * 1000;
        if (this.formData.timeType === 1) {
          obj.queryStartDate = this.formatDate(new Date(), 'yyyy-MM-dd');
          obj.queryEndDate = this.formatDate(new Date(), 'yyyy-MM-dd');
        } else if (this.formData.timeType === 2) {
          obj.queryStartDate = this.formatDate(
            new Date(new Date().getTime() - day_1),
            'yyyy-MM-dd'
          );
          obj.queryEndDate = this.formatDate(
            new Date(new Date().getTime() - day_1),
            'yyyy-MM-dd'
          );
        } else if (this.formData.timeType === 3) {
          obj.queryStartDate = this.formatDate(
            new Date(new Date().getTime() - day_7),
            'yyyy-MM-dd'
          );
          obj.queryEndDate = this.formatDate(new Date(), 'yyyy-MM-dd');
        } else if (this.formData.timeType === 4) {
          obj.queryStartDate = this.formatDate(
            new Date(new Date().getTime() - day_30),
            'yyyy-MM-dd'
          );
          obj.queryEndDate = this.formatDate(new Date(), 'yyyy-MM-dd');
        } else if (this.formData.timeType === 5) {
          obj.queryStartDate = this.formatDate(
            new Date(this.formData.time[0]),
            'yyyy-MM-dd'
          );
          obj.queryEndDate = this.formatDate(
            new Date(this.formData.time[1]),
            'yyyy-MM-dd'
          );
        }
        return obj[k];
      },

      /**
       * 获取数据
       */
      getData() {
        if (this.formData.timeType === 5 && (!this.formData.time[0] || !this.formData.time[1])) {
          this.$Notice.info({
            title: '提示',
            desc: '请选择时间',
          });
          return;
        }
        let beginTime = this.getTime('queryStartDate');
        let endTime = this.getTime('queryEndDate');
        beginTime = beginTime + ' 00:00:00';
        endTime = endTime + ' 23:59:59';
        let data = {
          queryStartDate: beginTime,
          queryEndDate: endTime
        };
        this.$axios({
          url: this.$api.member.queryMemberVisitDetail,
          data: data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.info = data || {};
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.getData();
      },
    }
  };
</script>